<template>
  <div id="ipoInfoChart">
    <div class="pages hidden" id="ipoPages">
      <page-title id="ipoText" ref="refPageTitle"></page-title>

      <div ref="refIpoTableHolder" v-if="isIpoTableVisible">
        <table-chart ref="refIpoTable" id="ipoTable" customClass="ipo_table_chart" />
      </div>

      <div ref="refDescTableHolder" v-if="isDescTableVisible">
        <table-chart ref="refDescTable" id="descTable" customClass="desc_table_chart" />
      </div>

      <div ref="refBasicTableHolder" v-if="isBasicTableVisible">
        <table-chart ref="refBasicTable" id="basicTable" customClass="basic_table_chart" />
      </div>
    </div>
  </div>
</template>
<script>
//特别注意：使用jquery的时候为了避免重名带来的干扰，选择器开头必须是marketChart
import $ from "jquery";
import { sleep, setRem } from "@/utils/utils.js";
import pageTitle from "@/businessComp/charts/pageTitle/index.vue";
import tableChart from "@/components/tableChart/index.vue";

export default {
  data: function () {
    return {
      titleText: null, //动画句柄
      isIpoTableVisible: false, //新股中心各种详情
      isDescTableVisible:false, //公司介绍和主营详情
      isBasicTableVisible:false, //股票基本信息
    };
  },
  components: {
    pageTitle,
    tableChart,
  },
  methods: {
    preview(commandData) {
      setRem(1080);
      this.show(commandData)
    },
    async hide() {
      return new Promise(async (resolve, reject) => {
        this.$refs.refPageTitle.hide();
        await sleep(200);
        this.isIpoTableVisible = false;
        this.isDescTableVisible = false;
        this.isBasicTableVisible = false;
        resolve();
      });
    },
    async show(commandData) {
      this.isIpoTableVisible = false;      
      this.isDescTableVisible = false;
      this.isBasicTableVisible = false;
      $("#ipoPages").removeClass("hidden");

      //设置标题
      this.$refs.refPageTitle.show(commandData);
      this.$nextTick(async () => {
        await sleep(300);
        if(["新股申购", "等待上市", "上市表现"].indexOf(commandData.type) > -1){          
          this.handleIpo(commandData);
        } else if (["公司简介", "主营业务"].indexOf(commandData.type) > -1) {
          this.handleDesc(commandData);
        } else if (commandData.type == "基本信息"){
          this.handleBasic(commandData);
        }
      });
    },
    handleIpo(commandData) {
      this.isIpoTableVisible = true;
      this.$nextTick(() => {
        this.renderIpoTableChart(commandData);
      });
    },
    renderIpoTableChart(commandData) {
      let width = this.$refs.refIpoTableHolder.clientWidth;
      const chartData = commandData.data.data
      for (var i=1;i<(chartData).length;i++){
        delete chartData[i].sDtSecCode
      }
      this.$refs.refIpoTable.TableChart(chartData, {
        width: width,
        height: width * 0.77,
        duration: 500,
        delay: 100,
        ease: "easeOutCubic",
      });
    },
    
    handleDesc(commandData) {
      this.isDescTableVisible = true;
      this.$nextTick(() => {
        this.renderDescTableChart(commandData);
      });
    },
    renderDescTableChart(commandData) {
      let width = this.$refs.refDescTableHolder.clientWidth;
      this.$refs.refDescTable.TableChart(commandData.data.data, {
        width: width,
        height: width * 0.77,
        duration: 500,
        delay: 100,
        ease: "easeOutCubic",
      });
    },

    handleBasic(commandData) {
      this.isBasicTableVisible = true;
      this.$nextTick(() => {
        this.renderBasicTableChart(commandData);
      });
    },
    renderBasicTableChart(commandData) {
      let width = this.$refs.refBasicTableHolder.clientWidth;
      this.$refs.refBasicTable.TableChart(commandData.data.data, {
        width: width,
        height: width * 0.77,
        duration: 500,
        delay: 100,
        ease: "easeOutCubic",
      });
    },

  },

  async mounted() {
    window.hide = this.hide;
    window.show = this.preview;
    this.$bus.$on("chartExit", () => {
      this.hide();
    });
  },
};
</script>
<style lang="less" scoped>
@import "index.less";
</style>
